import "./App.scss";
import ReactGA from "react-ga4";
import Footer from "./components/Layout/Footer";
import Header from "./components/Layout/Header";
import { BrowserRouter as Router, Routes, Route, useLocation } from "react-router-dom";
import HomePage from "./components/Home/HomePage";
import StudentSignup from "./components/Student/StudentSignup";
import LayoutSettings from "./components/Layout/LayoutSettings";
import EmployerSignup from "./components/Employer/EmployerSignup";
import ViewMoreJobs from "./components/Student/ViewMoreJobs";
import Login from "./components/User/Login";
import Applications from "./components/Student/Applications";
import Profile from "./components/Student/Profile";
import HelpCenter from "./components/Extras/HelpCenter";
import MyApplications from "./components/Student/MyApplications";
import MessageStudent from "./components/Student/MessageStudent";
import MessageEmployer from "./components/Employer/MessageEmployer";
import MessageStudentSecond from "./components/Student/MessageStudentSecond";
import MatchedJobs from "./components/Student/MatchedJobs";
import MatchedInternships from "./components/Student/MatchedInternships";
import Employer from "./components/Employer/Employer";
// import EmployerApplication from './components/Employer/EmployerApplication';
import EmployerApplications from "./components/EmployerNew/employerApplication";
import PostInternship from "./components/EmployerNew/PostIntership";
import ApplicationDetailsRecived from "./components/EmployerNew/ApplicationDetails";

// import PersonalDetails from "./components/Employer/PersonalDetails";
import Review from "./components/Student/Review";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import ForgotPassword from "./components/Extras/ForgotPassword";
import ResetPassword from "./components/Extras/ResetPassword";
import PostJobForm from "./components/Employer/PostJobForm";
import Preview from "./components/Employer/Preview";
import CheckOpening from "./components/Employer/CheckOpening";
import Plan from "./components/Extras/Plan";
import CoverLetter from "./components/Employer/CoverLetter";
import AddResume from "./components/Employer/AddResume";
import Header2 from "./components/Layout/Header2";
import { loadUser, logoutUser } from "./actions/userActions";
import RecoveryEmail from "./components/Extras/RecoveryEmail";
import EmployerPreview from "./components/Employer/EmployerPreview";
import ViewMoreInternships from "./components/Student/ViewMoreInternships";
import StudentSavedjob from "./components/Student/StudentSavedjob";
import ProtectedRoute from "./components/ProtectedRoute";
import Notfound from "./components/Notfound";
import EmployerprotectedRoute from "./components/EmployerprotectedRoute";
import StudentprotectedRoute from "./components/StudentprotectedRoute";
import AdminSignup from "./components/Admin/AdminSignup";
import AdminLogin from "./components/Admin/AdminLogin";
import AdminDashboard from "./components/Admin/AdminDashboard";
//import EmployerApplicationReceivedList from "./components/Employer/EmployerAplicationReceivedList";
import ApplicationDetails from "./components/Student/applicationDetails";
import Support from "./components/Student/Support";
import ContactUs from "./components/Student/ContactUs";
import EmployerDetail from "./components/Employer/EmployerDetail";
import CompanyDetail from "./components/Employer/CompanyDetail";
import CandidateDetail from "./components/Student/CandidateDetail";
import EditProfile from "./components/EmployerNew/EditProfile";
import EditProfileCompany from "./components/EmployerNew/EditProfileCompany";
import UserApplications from "./components/Student_New/UserApplications";
import PreviewJob from "./components/Student_New/PreviewJob";
import UserAppliedJob from "./components/Student_New/UserAppliedJob";
import EditResume from "./components/Student_New/EditResume";
import AppliedJobReview from "./components/Student_New/AppliedJobReview";
import Job from "./components/Student_New/Jobs";
import Internship from "./components/Student_New/Internship";
import ChangePassword from "./components/Dropdown/ChangePassword";
import ChangePasswordAdmin from "./components/Dropdown/ChangePasswordAdmin";
import ChangeEmail from "./components/Dropdown/ChangeEmail";
import { Helmet } from "react-helmet";
import Terms from "./components/Other/Tems";
import AboutUs from "./components/Other/AboutUs";
import Privicy from "./components/Other/Privicy";
import GDPR from "./components/Other/GDPR";
import CandidateDetailEmp from "./components/Student/CandidateDetailsEmp";
import Layout from "./components/Layout/Layout";
import AdminCandidates from "./components/Admin/AdminCandidates";
import AdminEmployers from "./components/Admin/AdminEmployers";
import AdminPostedJobsAndInternships from "./components/Admin/AdminPostedJobsAndInternships";
import AdminInputControl from "./components/Admin/AdminInputControl";
import EmployerBuyConnect from "./components/EmployerNew/EmployerBuyConnect";
import EmployerConnectInfo from "./components/EmployerNew/EmployerConnectInfo";
import axios from "axios";
import Agreement from "./components/Other/Agreement";
import HowItWorks from "./components/Other/HowItWorks";
import Testimony from "./components/Other/Testimony";
import Story from "./components/Other/Story";
import Faqs from "./components/Other/Faqs";
import debounce from "./utils/Debounce";
import PreviewCandidate from "./components/EmployerNew/Preview";
import LandingPageNew from "./components/Home/LandingPageNew";
import ChooseFreelancerOrEmployer from "./components/Layout/ChooseFreelancerOrEmployer";
import HowToHire from "./components/Other/HowToHire";
import TalentMarketPlace from "./components/Other/TalentMarketPlace";
import HireAnAgency from "./components/Other/HireAnAgency";
import Enterprise from "./components/Other/Enterprise";
import AnyHire from "./components/Other/AnyHire";
import ContractToHire from "./components/Other/ContractToHire";
import DirectContract from "./components/Other/DirectContract";
import HireWorldWide from "./components/Other/HireWorldWide";
import HowToFindWork from "./components/Other/HowToFindWork";
import TalentDirectContract from "./components/Other/TalentDirectContract";
import ContractToHireForTalent from "./components/Other/ContractToHireForTalent";
import PlatformChargesAndPayment from "./components/Other/LearnMore";
// import {  useSelector } from 'react-redux';
// import { Navigate } from 'react-router-dom';
import { initGA, logPageView } from "./utils/Analytics";

const checkDeleteLog = async (userId) => {
    try {
        const { data } = await axios.get("/api/admin/getDeleteUserIdInfo", {
            params: { userId },
        });
        return data.isDeleteHit;
    } catch (error) {
        console.error("Error checking delete log:", error);
        return false;
    }
};
const deleteDeleteLogEntry = async (userId) => {
    try {
        await axios.delete("/api/admin/deleteDeleteLogEntry", {
            data: { userId },
        });
    } catch (error) {
        console.error("Error deleting delete log entry:", error);
    }
};
const AnalyticsTracker = () => {
    const location = useLocation();

    useEffect(() => {
        logPageView(location.pathname + location.search); // Log page views on route change
    }, [location]);

    return null;
};
const RouteChangeTracker = () => {
    const location = useLocation();

    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: location.pathname + location.search });
    }, [location]);

    return null; // This component doesn't render anything
};
function App() {
    useEffect(() => {
        initGA(); // Initialize Google Analytics when the app starts
    }, []);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(loadUser());
    }, [dispatch]);
    const { user } = useSelector((state) => state.user);

    // const isAuthenticated = useSelector((state) => state.user.isAuthenticated);
    // const userRole = useSelector((state) => state.user.role);
    useEffect(() => {
        const handleStorageChange = (event) => {
            if (event.key === "token" && event.oldValue && !event.newValue) {
                // Token was removed, log out the user
                dispatch(logoutUser());
            }
        };

        window.addEventListener("storage", handleStorageChange);

        // Listen for logout events from other tabs
        const broadcastChannel = new BroadcastChannel("logout_channel");
        broadcastChannel.onmessage = (event) => {
            if (event.data === "logout") {
                // Logout message received, reload the page
                window.location.reload();
                // window.location.href = '/login';
            }
        };

        return () => {
            window.removeEventListener("storage", handleStorageChange);
            broadcastChannel.close();
        };
    }, [dispatch]);
    useEffect(() => {
        const handleDeleteLogCheck = async () => {
            const localStorageId = localStorage.getItem("id");
            if (localStorageId) {
                const isDeleteHit = await checkDeleteLog(localStorageId);
                if (isDeleteHit) {
                    localStorage.clear();
                    dispatch(logoutUser());
                    // await deleteDeleteLogEntry(localStorageId);
                    window.location.href = "/login";
                }
            }
        };

        handleDeleteLogCheck();
    }, [user, dispatch]);

    useEffect(() => {
        const isloggedin = localStorage.getItem("token");
        if (isloggedin === null) {
            console.log("not logged in");
            return;
        }
        if (!user) {
            return;
        }
        if (user?.role === "User") {
            // console.log("you are candidate");
            return;
        }

        const updateLastActive = async () => {
            await axios
                .post("/api/user/activity-record", {
                    email: user?.email,
                })
                .then((res) => {
                    // console.log(res?.data, "result");
                })
                .catch((error) => {
                    console.log(error, "error");
                });
        };

        const debouncedUpdateLastActive = debounce(updateLastActive, 3000);

        // console.log(user, "client email");
        const handleUserActivity = () => {
            debouncedUpdateLastActive();
        };

        document.addEventListener("click", handleUserActivity);
        document.addEventListener("mousemove", handleUserActivity);
        document.addEventListener("keypress", handleUserActivity);

        // Update the last active status on initial load
        debouncedUpdateLastActive();

        // Clean up event listeners on unmount
        return () => {
            document.removeEventListener("click", handleUserActivity);
            document.removeEventListener("mousemove", handleUserActivity);
            document.removeEventListener("keypress", handleUserActivity);
        };
    }, [user]);

    const hideFooterPaths = ["/signup"]; // Paths where footer should be hidden

    const isStudent = () => {
        // Return true if student is logged in
        return localStorage.getItem("userType") === "User";
    };

    const isEmployer = () => {
        // Return true if employer is logged in
        return localStorage.getItem("userType") === "Employer";
    };

    // Combined protected route component
    const MessageRoute = () => {
        // Check if user is authenticated first
        const isAuthenticated = localStorage.getItem("token"); // Or however you check authentication

        if (!isAuthenticated) {
            return <Navigate to="/login" />;
        }

        if (isStudent()) {
            return <StudentprotectedRoute Component={MessageStudent} />;
        }

        if (isEmployer()) {
            return <EmployerprotectedRoute Component={MessageEmployer} />;
        }

        // Fallback if somehow neither condition is met
        return <Navigate to="/" />;
    };
    return (
        <>
            <Router>
                <AnalyticsTracker />
                <RouteChangeTracker />
                <LayoutSettings />
                <Layout>
                    <Header2 expanded={true} />
                    <div style={{ marginBottom: "78px" }}></div>
                    <Routes>
                        <Route exact path="/" element={<LandingPageNew />} />
                        <Route exact path="/signup" element={<ChooseFreelancerOrEmployer />} />
                        <Route exact path="/freelancerSignup" element={<StudentSignup />} />
                        <Route exact path="/employeeSignup" element={<EmployerSignup />} />
                        <Route exact path="/login" element={<Login />} />
                        <Route path="/AdminSignup" element={<AdminSignup />} />
                        <Route path="/admin_login" element={<AdminLogin />} />
                        <Route path="/admin_change_password" element={<ChangePasswordAdmin />} />
                        <Route path="/admin_freelancer" element={<AdminCandidates />} />
                        <Route path="/admin_employers" element={<AdminEmployers />} />
                        <Route path="/admin_input_control" element={<AdminInputControl />} />
                        <Route path="/admin" element={<AdminPostedJobsAndInternships />} />
                        <Route exact path="/proposal" element={<StudentprotectedRoute Component={UserApplications} />} />
                        

                        <Route exact path="/support" element={<Support />} />
                        <Route exact path="/myProfile" element={<EmployerprotectedRoute Component={EditProfile} />} />
                        <Route exact path="/myProfile/company" element={<EmployerprotectedRoute Component={EditProfileCompany} />} />
                        <Route exact path="/matchedHourlyProject/:filterType" element={<Job />} />
                        <Route exact path="/matchedFixedProject/:filterType" element={<Internship />} />
                        <Route exact path="/otpverification" element={<ForgotPassword />} />
                        <Route exact path="/password/reset" element={<ResetPassword />} />
                        <Route exact path="/freelancerbuyconnects" element={<StudentprotectedRoute Component={EmployerBuyConnect} />} />
                        <Route exact path="/freelancerconnectsinformation" element={<StudentprotectedRoute Component={EmployerConnectInfo} />} />
                        <Route exact path="/applicationdetails" element={<EmployerprotectedRoute Component={ApplicationDetails} />} />
                        <Route exact path="/message" element={<MessageRoute />} />
                        <Route exact path="/contactus" element={<ContactUs />} />
                        {/*Other Routes*/}
                        <Route exact path="/term-condition" element={<Terms />} />
                        <Route exact path="/user-agreement" element={<Agreement />} />
                        <Route exact path="/about-us" element={<AboutUs />} />
                        <Route exact path="/privacy" element={<Privicy />} />
                        <Route exact path="/GDPR" element={<GDPR />} />
                        <Route exact path="/how-it-works" element={<HowItWorks />} />
                        <Route exact path="/testimony" element={<Testimony />} />
                        <Route exact path="/story" element={<Story />} />
                        <Route exact path="/faqs" element={<Faqs />} />
                        <Route exact path="/how-to-hire" element={<HowToHire />} />
                        <Route exact path="/talent-marketplace" element={<TalentMarketPlace />} />
                        <Route exact path="/hire-an-agency" element={<HireAnAgency />} />
                        <Route exact path="/enterprise" element={<Enterprise />} />
                        <Route exact path="/any-hire" element={<AnyHire />} />
                        <Route exact path="/how-to-find-work" element={<HowToFindWork />} />
                        <Route exact path="/contract-to-hire" element={<ContractToHire />} />
                        <Route exact path="/contract-to-hire-talent" element={<ContractToHireForTalent />} />
                        <Route exact path="/direct-contracts" element={<DirectContract />} />
                        <Route exact path="/learn-more" element={<PlatformChargesAndPayment />} />
                        <Route exact path="/talent-direct-contracts" element={<TalentDirectContract />} />
                        <Route exact path="/hire-worldwide" element={<HireWorldWide />} />
                        <Route exact path="/change-email" element={<ChangeEmail />} />
                        <Route exact path="/change-password" element={<ChangePassword />} />
                        <Route exact path="/newpost/:id" element={<EmployerprotectedRoute Component={PostInternship} />} />
                      
                        <Route exact path="/review/:appliedJobId" element={<AppliedJobReview />} />

                        <Route exact path="/employerApplication/:type" element={<EmployerprotectedRoute Component={EmployerApplications} />} />
                        <Route exact path="/employerappreceived/:type" element={<EmployerprotectedRoute Component={ApplicationDetailsRecived} />} />
                        <Route exact path="/preview/:jobId" element={<PreviewJob />} />
                        <Route exact path="/coverletterpage/:jobId" element={<ProtectedRoute Component={UserAppliedJob} />} />
                        <Route exact path="/recoveryemail" element={<RecoveryEmail />} />
                        <Route exact path="/freelancerSavedProject" element={<StudentprotectedRoute Component={StudentSavedjob} />} />
                        <Route exact path="/profile" element={<StudentprotectedRoute Component={EditResume} />} />
                        <Route exact path="/freelancer-details" element={<CandidateDetail />} />
                        <Route exact path="/previewFreelancer/:CandidateId" element={<PreviewCandidate />} />
                        <Route path="*" element={<Notfound />} />

                        {/* <Route exact path="/home" element={<LandingPageNew />} /> */}
                        {/*<Route path="/admin" element={<AdminDashboard />} />*/}
                        {/* <Route exact path="/applications" element={<UserApplications />} /> */}
                        {/* <Route exact path="/searchJobs" element={<ViewMoreJobs />} /> */}
                        {/* <Route exact path="/searchInternships" element={<ViewMoreInternships />} /> */}
                        {/* <Route exact path="/editProfileUser" element={<Profile />} /> */}
                        {/* <Route exact path="/myProfile" element={<EditProfile />} /> */}
                        {/* <Route exact path="/myProfile/company" element={<EditProfileCompany />}/> */}
                        {/* <Route exact path="/help" element={<HelpCenter />} /> */}
                        {/* <Route exact path="/plan" element={<Plan />} /> */}
                        {/* <Route exact path="/myApplications" element={<MyApplications />} /> */}
                        {/* <Route exact path="/messageStudent" element={<MessageStudent />} /> */}
                        {/* <Route exact path='/matchedJobs'  element={<ProtectedRoute Component={MatchedJobs} />} /> */}
                        {/* <Route exact path="/employer/*" element={<Employer />} /> */}
                        {/* <Route exact path="/messageStudentSecond" element={<MessageStudentSecond />} /> */}
                        {/* <Route exact path="/employerbuyconnects" element={<EmployerBuyConnect/>}/> */}
                        {/* <Route exact path="/employerappreceived" element={<EmployerprotectedRoute Component={EmployerApplicationReceivedList} />} /> */}
                        {/* <Route exact path='/employerApplication' element={<EmployerApplication />} /> */}
                        {/* <Route exact path="/support" element={<EmployerprotectedRoute Component={Support} />} /> */}
                        {/* <Route exact path="/message" element={<StudentprotectedRoute Component={MessageStudent} />} />
                        <Route exact path="/message" element={<EmployerprotectedRoute Component={MessageEmployer} />} /> */}
                        {/* <Route exact path="/review" element={<AppliedJobReview />} />
                        <Route exact path="/preview" element={<PreviewJob />} />
                        <Route exact path="/emppreview" element={<EmployerPreview />} /> */}
                        {/* <Route exact path='/coverletterpage' element={<CoverLetter />} /> */}
                        {/* <Route exact path='/coverletterpage'  element={isAuthenticated ? <CoverLetter /> : <Navigate to='/login' />}/> */}
                        {/*  <Route exact path="/candidate-details-emp" element={<CandidateDetailEmp />} />*/}
                        {/* <Route exact path="/addResume" element={<EditResume />} /> */}
                        {/* <Route exact path="/studentSavedjob" element={<StudentSavedjob />} /> */}
                        {/*  <Route exact path="/addresume" element={<AddResume />} />*/}
                        {/* <Route exact path='/personalDetails' element={<PersonalDetails />} /> 
                        <Route exact path="/personalDetails" element={<EmployerprotectedRoute Component={PersonalDetails} />}/>*/}
                        {/* <Route exact path="/personalDetails"element={<EmployerprotectedRoute Component={PersonalDetails} />}/> */}
                        {/* <Route exact path="/checkopening" element={<CheckOpening />} /> */}
                        {/* <Route exact path="/postjob" element={<PostJobForm />} /> */}
                        {/* <Route exact path="/emppreview" element={<EmployerPreview />} /> */}
                        {/* <Route exact path="/coverletterpage" element={<ProtectedRoute Component={CoverLetter} />} /> */}
                        {/* <Route exact path="/employer-details" element={<EmployerDetail />} /> */}
                        {/* <Route exact path="/company-detail" element={<CompanyDetail />} /> */}
                    </Routes>
                    {!hideFooterPaths.includes(location.pathname) && <Footer expanded={true} />}
                </Layout>
            </Router>
        </>
    );
}

export default App;
