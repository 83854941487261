import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { logoutUser } from "../../actions/userActions";
import Sidebar from "react-sidebar";
import Logo from "../../assets/images/Workcroft.png";
import axios from "axios";
import io from "socket.io-client";
import toast, { Toaster } from "react-hot-toast";
import "./css/Header2.scss";
import Dropdown from "./DropDown";
import DropdownAdmin from "./DropdownAdmin";
import { BsChatLeftText } from "react-icons/bs";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import { RxHamburgerMenu } from "react-icons/rx";
import { IoIosArrowDown } from "react-icons/io";
import { IoIosArrowUp } from "react-icons/io";
import { logEvent } from "../../utils/Analytics";
function Header2() {
    const location = useLocation();
    const [data, setData] = useState("");
    const [register, setRegister] = useState(false);
    const [profile, setProfile] = useState(false);
    const navigate = useNavigate();
    const { user } = useSelector((state) => state.user);
    const [loading, setLoading] = useState(true);

    const userLoggedIn = localStorage.getItem("userLoggedIn");
    const userType = localStorage.getItem("userType");
    const userProfile = localStorage.getItem("userProfile");
    const basicDetails = JSON.parse(localStorage.getItem("basicDetails"));

    const dispatch = useDispatch();

    const handleLinkClick = () => {
        window.scrollTo(0, 0); // Scroll to the top of the window
    };

    const Logout = () => {
        clearLocalStorage();
        dispatch(logoutUser());
        navigate("/");
        window.location.reload();
    };
    const checkUserExists = async () => {
        console.log("call the api");
        try {
            const userData = await fetch("/api/user/me", {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    token: localStorage.getItem("token"),
                },
            });
            const json = await userData.json();
            console.log(json);
            if (json.user) {
                return true;
            } else {
                return false;
            }
        } catch (error) {
            console.error("Error checking user existence:", error);
            return false;
        }
    };

    const clearLocalStorage = () => {
        localStorage.removeItem("userLoggedIn");
        localStorage.removeItem("userType");
        localStorage.removeItem("userProfile");
        localStorage.removeItem("basicDetails");
        // Add any other items that need to be cleared
    };
    useEffect(() => {
        // Skip validation for public routes
        const publicRoutes = ["/login", "/signup", "/forgot-password"];
        if (publicRoutes.includes(location.pathname)) {
            setLoading(false);
            return;
        }

        const validateUser = async () => {
            console.log("call validation ");
            if (userLoggedIn && basicDetails) {
                const userExists = await checkUserExists(basicDetails.userId);
                console.log("user exists", userExists);
                if (!userExists) {
                    clearLocalStorage();
                    dispatch({ type: "LOGOUT" });
                    navigate("/login");
                }
            }
            setLoading(false);
        };

        validateUser();
    }, [location.pathname]);
    const LogoutAdmin = () => {
        dispatch(logoutUser());
        navigate("/admin_login");
    };

    const handleRegister = () => {
        setRegister((prevRegister) => !prevRegister);
    };

    const handleProfile = () => {
        setProfile((prevProfile) => !prevProfile);
    };

    const isAuthenticated = useSelector((state) => state.user.isAuthenticated);

    const [unreadMsg, setUnreadMsg] = useState(0);

    const GetRoomData = async () => {
        if (user && user._id) {
            const id = user && user._id;
            try {
                const { data } = await axios.post(`/chats/unread/${id}`, {
                    headers: {
                        "Content-Type": "application/json",
                        token: localStorage.getItem("token"),
                    },
                });
                const unreadCount = data.filter((message) => message.messages.filter((msg) => msg.status === "unread" && msg.receiver === id).length > 0).length;

                setUnreadMsg(unreadCount);
            } catch (err) {
                console.error("Error fetching data:" + err);
            }
        }
    };

    useEffect(() => {
        const interval = setInterval(() => {
            GetRoomData();
        }, 1000); // 1000 milliseconds = 1 second

        // Cleanup function to clear the interval when component unmounts or dependencies change
        return () => clearInterval(interval);
    }, [user]);

    const handleSavedJobClick = () => {
        if (isAuthenticated) {
            navigate("/freelancerSavedProject");
        } else {
            alert("Please log in to view saved projects.");
        }
    };

    const [sidebarOpen, setSidebarOpen] = useState(false);

    // SIDE BAR

    const [open, setOpen] = React.useState(false);

    const toggleDrawer = (newOpen) => () => {
        setOpen(newOpen);
    };
    const navigatePath = (path) => {
        navigate(path);
    };

    const [resumeData, setResumeData] = useState("");
    const getResumeData = async () => {
        try {
            const { data } = await axios.get("/api/user/getResume", {
                headers: {
                    "Content-Type": "application/json",
                    token: localStorage.getItem("token"),
                },
            });
            setResumeData(data.resume);
        } catch {
            console.log("Something went wrong");
        }
    };
    useEffect(() => {
        if (user && user?.resume) {
            getResumeData();
        }
    }, [user]);
    const photoUrl = resumeData?.PersonalDetails?.photo?.url;

    const handleAddEvent = (str) => {
        logEvent("Button", `Clicked ${str} button`, user ? user.role : "Guest");
    };
    return (
        <>
            {<Toaster /> /*  */}

            {/*ADMIN PANEL*/}
            {user && user.role === "Admin" ? (
                <>
                    <div className="Admin">
                        <nav className="navbar navbar-expand-lg navbar-light sticky-top bg-light rounded" style={{ position: "Fixed", width: "100%" }}>
                            <div className="container-fluid">
                                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                                    <span className="navbar-toggler-icon"></span>
                                </button>
                                <div className="logo-box  " style={{ marginLeft: "40px" }}>
                                    <Link to="/admin" onClick={handleLinkClick}>
                                        <img src={Logo} alt="logo" style={{ height: "68px" }} />
                                    </Link>
                                </div>
                                <div className="d-flex" style={{ marginRight: "40px" }}>
                                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                                        <ul className="navbar-nav me-auto mb-2 mb-lg-0 p-4">
                                            <li className="nav-item mr-5" style={{ display: "flex", alignItems: "flex-end", justifyContent: "center", color: "black" }}>
                                                <Link to="/admin_input_control" onClick={handleAddEvent("Admin Input Control")} className={`nav-link active ${location.pathname === "/admin_input_control" ? "border-2 border-bottom border-primary" : ""}`} aria-current="page" style={{ cursor: "pointer" }}>
                                                    <b>Admin Input Control</b>
                                                </Link>
                                            </li>
                                            <li className="nav-item mr-5" style={{ display: "flex", alignItems: "flex-end", justifyContent: "center", color: "black" }}>
                                                <Link to="/admin_freelancer" onClick={handleAddEvent("Freelancer")} className={`nav-link active ${location.pathname === "/admin_freelancer" ? "border-2 border-bottom border-primary" : ""}`} aria-current="page" style={{ cursor: "pointer" }}>
                                                    <b>Freelancer</b>
                                                </Link>
                                            </li>
                                            <li className="nav-item mr-5" style={{ display: "flex", alignItems: "flex-end", justifyContent: "center", color: "black" }}>
                                                <Link to="/admin_employers" onClick={handleAddEvent("Employers")} className={`nav-link active ${location.pathname === "/admin_employers" ? "border-2 border-bottom border-primary" : ""}`} aria-current="page" style={{ cursor: "pointer" }}>
                                                    <b>Employers</b>
                                                </Link>
                                            </li>
                                            <li className="nav-item mr-5" style={{ display: "flex", alignItems: "flex-end", justifyContent: "center", marginRight: "40px", color: "black" }}>
                                                <Link to="/admin" onClick={handleAddEvent("Posted Hourly/Fixed")} className={`nav-link active ${location.pathname === "/admin" ? "border-2 border-bottom border-primary" : ""}`} aria-current="page" style={{ cursor: "pointer" }}>
                                                    <b>Posted Hourly/Fixed</b>
                                                </Link>
                                            </li>
                                            <li className="nav-item dropdown ml-5" style={{ display: "flex", alignItems: "flex-end", justifyContent: "center", color: "black" }}>
                                                {/* dropdown-toggle removed to remove the down arrow because the arrow was not aligned */}
                                                <DropdownAdmin user={user} />
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </nav>
                    </div>

                    {/*ADMIN MOBILE*/}
                    <div className="AdminMob">
                        <nav className="navbar navbar-expand-lg navbar-light  sticky-top bg-body rounde" style={{ position: "Fixed", width: "100%" }}>
                            <div className="container-fluid">
                                <Button style={{ color: "black" }} onClick={toggleDrawer(true)}>
                                    <RxHamburgerMenu style={{ fontSize: "22px" }} />
                                </Button>

                                <div className="logo-box  ">
                                    <Link to="/admin" onClick={handleLinkClick}>
                                        <img className="imgofNav" src={Logo} alt="logo" />
                                    </Link>
                                </div>

                                <div className="d-flex">
                                    <div className="" id="navbarSupportedContent">
                                        <ul className="navbar-nav me-auto mb-2 mb-lg-0 p-4">
                                            <li className="nav-item" style={{ position: "relative" }}>
                                                <button
                                                    className="LogoutAdmin"
                                                    onClick={() => LogoutAdmin()}
                                                    style={{
                                                        fontSize: "13px",
                                                        borderRadius: "20px",
                                                        width: "80px",
                                                    }}
                                                >
                                                    Logout
                                                </button>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </nav>
                    </div>
                    <div>
                        <Drawer open={open} onClose={toggleDrawer(false)}>
                            {/*DrawerList*/}
                            <div className="px-5">
                                <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                    <Link to="/admin">
                                        <img src={Logo} alt="logo" style={{ height: "58px", marginTop: "10px", marginLeft: "0%", marginBottom: "20px" }} />
                                    </Link>
                                </div>
                                <p style={{ fontWeight: "500", paddingLeft: "20px" }}>{user && user.name}</p>
                                <p style={{ fontSize: "13px", marginTop: "-8px", paddingLeft: "20px" }}>{user && user.email}</p>
                                <hr className="my-4" style={{ height: "0.1px" }} />
                                <div className="mt-3">
                                    <p
                                        onClick={() => {
                                            navigatePath("/admin"), handleAddEvent("Dashboard");
                                        }}
                                        className={location.pathname === "/admin" ? "SidebarTitleActive" : "SidebarTitle"}
                                    >
                                        Dashboard
                                    </p>
                                    <p
                                        onClick={() => {
                                            navigatePath("/admin_freelancer"), handleAddEvent("Freelancer");
                                        }}
                                        className={location.pathname === "/admin_freelancer" ? "SidebarTitleActive" : "SidebarTitle"}
                                    >
                                        Freelancer
                                    </p>
                                    <p
                                        onClick={() => {
                                            navigatePath("/admin_employers"), handleAddEvent("Employers");
                                        }}
                                        className={location.pathname === "/admin_employers" ? "SidebarTitleActive" : "SidebarTitle"}
                                    >
                                        Employers
                                    </p>
                                    <p
                                        onClick={() => {
                                            navigatePath("/admin_input_control"), handleAddEvent("Admin Input Control");
                                        }}
                                        className={location.pathname === "/admin_input_control" ? "SidebarTitleActive" : "SidebarTitle"}
                                    >
                                        Admin Input Control
                                    </p>
                                </div>
                                <hr className="my-4" style={{ height: "0.1px" }} />
                                <div>
                                    <p
                                        onClick={() => {
                                            navigatePath("/admin_change_password"), handleAddEvent("Change Password");
                                        }}
                                        className={location.pathname === "/admin_change_password" ? "SidebarTitleActive" : "SidebarTitle"}
                                    >
                                        Change Password
                                    </p>
                                    <p onClick={LogoutAdmin} className="SidebarTitle">
                                        Logout
                                    </p>
                                </div>
                            </div>
                        </Drawer>
                    </div>
                </>
            ) : (
                <>
                    <div className="navigation">
                        <nav className="navbar navbar-expand-lg sticky-top rounded" style={{ position: "Fixed", width: "100%", backgroundColor: "white", boxShadow: "none", borderBottomWidth: "2px", borderBottomStyle: "solid", borderBottomColor: "#AFAFAF" }}>
                            <div className="container-fluid">
                                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                                    <span className="navbar-toggler-icon"></span>
                                </button>

                                {user && user.role === "Employer" ? (
                                    <div className="logo-box  " style={{ marginLeft: "40px" }}>
                                        <Link to="/employerApplication/Hourly" onClick={() => handleLinkClick()}>
                                            <img src={Logo} alt="logo" style={{ height: "68px" }} />
                                        </Link>
                                    </div>
                                ) : (
                                    <div className="logo-box  " style={{ marginLeft: "40px" }}>
                                        <a href="/" onClick={handleLinkClick}>
                                            <img src={Logo} alt="logo" style={{ height: "68px" }} />
                                        </a>
                                    </div>
                                )}
                                <div className="d-flex" style={{ marginRight: "40px" }}>
                                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                                        <ul className="navbar-nav me-auto mb-2 mb-lg-0 p-4">
                                            {((user && user.role === "Employer") || userType === "Employer") && userLoggedIn ? (
                                                <>
                                                    <li className="nav-item mr-5" style={{ display: "flex", alignItems: "flex-end", justifyContent: "center" }}>
                                                        <Link to="/employerApplication/Hourly" onClick={handleAddEvent("Dashboard")} className={`nav-link active ${location.pathname.startsWith("/employerApplication") ? "border-2 border-bottom border-primary" : ""}`} aria-current="page" style={{ cursor: "pointer", color: "black" }}>
                                                            <b>Dashboard</b>
                                                        </Link>
                                                    </li>
                                                    <li className="nav-item mr-5" style={{ display: "flex", alignItems: "flex-end", justifyContent: "center" }}>
                                                        <Link to="/freelancer-details" onClick={handleAddEvent("Find Talent")} className={`nav-link active ${location.pathname === "/freelancer-details" ? "border-2 border-bottom border-primary" : ""}`} aria-current="page" style={{ cursor: "pointer", color: "black" }}>
                                                            <b>Find Talent</b>
                                                        </Link>
                                                    </li>
                                                    <li className="nav-item mr-5" style={{ display: "flex", alignItems: "flex-end", justifyContent: "center" }}>
                                                        <Link to="/newpost/0" onClick={handleAddEvent("Post Project")} className={`nav-link active ${location.pathname === "/newpost/0" ? "border-2 border-bottom border-primary" : ""}`} aria-current="page" style={{ cursor: "pointer", color: "black" }}>
                                                            <b>Post Project</b>
                                                        </Link>
                                                    </li>
                                                    <li
                                                        className="mb-3 mr-5"
                                                        style={{
                                                            position: "relative",
                                                        }}
                                                    >
                                                        <Link to="/message" onClick={handleAddEvent("Message")} style={{ display: "flex", alignItems: "center" }} className="mt-3 mx-4">
                                                            <BsChatLeftText style={{ fontSize: "20px" }} />
                                                        </Link>
                                                        {unreadMsg !== 0 && (
                                                            <span
                                                                style={{
                                                                    position: "absolute",
                                                                    top: "0",
                                                                    right: "0",
                                                                    borderRadius: "50%",
                                                                    width: "20px",
                                                                    height: "20px",
                                                                    fontWeight: "700",
                                                                    background: "#FFA333",
                                                                    fontSize: "1rem",
                                                                    border: "1px solid #fff",
                                                                    textAlign: "center",
                                                                    paddingTop: "2px",
                                                                    color: "white",
                                                                }}
                                                            >
                                                                {unreadMsg > 9 ? "9+" : unreadMsg}
                                                            </span>
                                                        )}
                                                    </li>
                                                </>
                                            ) : (
                                                <>
                                                    {user || userLoggedIn ? (
                                                        <>
                                                            <li className="nav-item mr-5" style={{ display: "flex", alignItems: "flex-end", justifyContent: "center" }}>
                                                                <Link to="/proposal" onClick={handleAddEvent("Applications")} className={`nav-link active ${location.pathname === "/proposal" ? "border-2 border-bottom border-primary" : ""}`} aria-current="page" style={{ cursor: "pointer", color: "black" }}>
                                                                    <b>Proposal </b>
                                                                </Link>
                                                            </li>
                                                        </>
                                                    ) : (
                                                        <>
                                                            <li className="nav-item mr-5" style={{ display: "flex", alignItems: "flex-end", justifyContent: "center" }}>
                                                                <Link to="/freelancer-details" onClick={handleAddEvent("Find Talent")} className={`nav-link active ${location.pathname === "/freelancer-details" ? "border-2 border-bottom border-primary" : ""}`} aria-current="page" style={{ cursor: "pointer", color: "black" }}>
                                                                    {/* <b>Job Seekers</b> */}
                                                                    <b>Find Talent</b>
                                                                </Link>
                                                            </li>
                                                        </>
                                                    )}
                                                    <li className="nav-item mr-5" style={{ display: "flex", alignItems: "flex-end", justifyContent: "center" }}>
                                                        <Link to="/matchedHourlyProject/all" onClick={handleAddEvent("Hourly Projects")} className={`nav-link active ${location.pathname === "/matchedHourlyProject/all" ? "border-2 border-bottom border-primary" : ""}`} aria-current="page" style={{ cursor: "pointer", color: "black" }}>
                                                            <b>Find Hourly Projects</b>
                                                        </Link>
                                                    </li>
                                                    <li className="nav-item mr-5" style={{ display: "flex", alignItems: "flex-end", justifyContent: "center" }}>
                                                        <Link to="/matchedFixedProject/all" onClick={handleAddEvent("Fixed Cost Projects")} className={`nav-link active ${location.pathname === "/matchedFixedProject/all" ? "border-2 border-bottom border-primary" : ""}`} aria-current="page" style={{ cursor: "pointer", color: "black" }}>
                                                            <b>Find Fixed Cost Projects</b>
                                                        </Link>
                                                    </li>

                                                    {user || userLoggedIn ? (
                                                        <>
                                                            <li className="mb-3 mr-5" style={{ position: "relative" }}>
                                                                <Link
                                                                    to="/message"
                                                                    style={{
                                                                        display: "flex",
                                                                        alignItems: "center",
                                                                    }}
                                                                    onClick={handleAddEvent("Message")}
                                                                    className="mt-3 mx-4"
                                                                >
                                                                    <BsChatLeftText style={{ fontSize: "20px" }} />
                                                                </Link>
                                                                {unreadMsg !== 0 && (
                                                                    <span
                                                                        style={{
                                                                            position: "absolute",
                                                                            top: "0",
                                                                            right: "0",
                                                                            borderRadius: "50%",
                                                                            width: "20px",
                                                                            height: "20px",
                                                                            fontWeight: "700",
                                                                            background: "#ffa333",
                                                                            fontSize: "1rem",
                                                                            border: "1px solid #fff",
                                                                            textAlign: "center",
                                                                            paddingTop: "2px",
                                                                            color: "white",
                                                                        }}
                                                                    >
                                                                        {unreadMsg > 9 ? "9+" : unreadMsg}
                                                                    </span>
                                                                )}
                                                            </li>
                                                        </>
                                                    ) : (
                                                        <></>
                                                    )}
                                                </>
                                            )}

                                            {user || userLoggedIn ? (
                                                <>
                                                    <li className="nav-item dropdown mx-4" style={{ color: "black" }}>
                                                        {/* dropdown-toggle removed to remove the down arrow because the arrow was not aligned */}
                                                        <Dropdown user={user} />
                                                    </li>
                                                </>
                                            ) : (
                                                <>
                                                    <li className="nav-item mr-5" style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                                        <Link to="/login" onClick={handleAddEvent("Login")} aria-current="page" style={{ cursor: "pointer", color: "black", marginTop: "10px" }}>
                                                            <b>Login</b>
                                                        </Link>
                                                    </li>

                                                    <li className="nav-item">
                                                        <a className="nav-link" onClick={handleAddEvent("Sign up")} href="/signup" style={{ color: "white", backgroundColor: "#1F57C3", padding: "10px 20px", borderRadius: "10px", marginTop: "5px" }}>
                                                            Sign up
                                                        </a>
                                                    </li>
                                                </>
                                            )}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </nav>
                    </div>

                    {/*Mobile Navbar*/}
                    <div className="nav2">
                        <nav className="navbar navbar-expand-lg navbar-light  sticky-top bg-body rounde" style={{ position: "Fixed", width: "100%" }}>
                            <div className="container-fluid">
                                <Button style={{ color: "black" }} onClick={toggleDrawer(true)}>
                                    <RxHamburgerMenu style={{ fontSize: "22px" }} />
                                </Button>

                                {((user && user.role === "Employer") || userType === "Employer") && userLoggedIn ? (
                                    <div className="logo-box">
                                        <Link to="/employerApplication/Hourly" onClick={handleLinkClick}>
                                            <img className="imgofNav" src={Logo} alt="logo" />
                                        </Link>
                                    </div>
                                ) : (
                                    <div className="logo-box  ">
                                        <a href="/" onClick={handleLinkClick}>
                                            <img className="imgofNav" src={Logo} alt="logo" />
                                        </a>
                                    </div>
                                )}

                                <div className="d-flex">
                                    <div className="" id="navbarSupportedContent">
                                        <ul className="navbar-nav me-auto mb-2 mb-lg-0 p-4">
                                            {((user && user.role === "Employer") || userType === "Employer") && userLoggedIn ? (
                                                <>
                                                    <li
                                                        className=""
                                                        style={{
                                                            position: "relative",
                                                        }}
                                                    >
                                                        <Link to="/message" style={{ display: "flex", alignItems: "center" }} className="mt-3 mx-4" onClick={handleAddEvent("Message")}>
                                                            <BsChatLeftText style={{ fontSize: "20px" }} />
                                                        </Link>

                                                        <span
                                                            style={{
                                                                position: "absolute",
                                                                top: "0",
                                                                right: "0",
                                                                borderRadius: "50%",
                                                                width: "20px",
                                                                height: "20px",
                                                                fontWeight: "700",
                                                                background: "#FFA333",
                                                                fontSize: "1rem",
                                                                border: "1px solid #fff",
                                                                textAlign: "center",
                                                                paddingTop: "2px",
                                                                color: "white",
                                                            }}
                                                        >
                                                            {unreadMsg > 9 ? "9+" : unreadMsg}
                                                        </span>
                                                    </li>
                                                </>
                                            ) : (
                                                <>
                                                    {user || userLoggedIn ? (
                                                        <>
                                                            <li
                                                                className=""
                                                                style={{
                                                                    position: "relative",
                                                                }}
                                                            >
                                                                <Link
                                                                    to="/message"
                                                                    style={{
                                                                        display: "flex",
                                                                        alignItems: "center",
                                                                    }}
                                                                    onClick={handleAddEvent("Message")}
                                                                    className="mt-3 mx-4"
                                                                >
                                                                    <BsChatLeftText style={{ fontSize: "20px" }} />
                                                                </Link>

                                                                <span
                                                                    style={{
                                                                        position: "absolute",
                                                                        top: "0",
                                                                        right: "0",
                                                                        borderRadius: "50%",
                                                                        width: "20px",
                                                                        height: "20px",
                                                                        fontWeight: "700",
                                                                        background: "#FFA333",
                                                                        fontSize: "1rem",
                                                                        border: "1px solid #fff",
                                                                        textAlign: "center",
                                                                        paddingTop: "2px",
                                                                        color: "white",
                                                                    }}
                                                                >
                                                                    {unreadMsg > 9 ? "9+" : unreadMsg}
                                                                </span>
                                                            </li>
                                                        </>
                                                    ) : (
                                                        <>
                                                            <li className="nav-item">
                                                                <a className="nav-link" onClick={handleAddEvent("sign up")} href="/signup" style={{ color: "white", backgroundColor: "#1F57C3", padding: "5px 20px", borderRadius: "10px" }}>
                                                                    Sign up
                                                                </a>
                                                            </li>
                                                        </>
                                                    )}
                                                </>
                                            )}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </nav>
                    </div>

                    <div>
                        <Drawer open={open} onClose={toggleDrawer(false)}>
                            {/*DrawerList*/}
                            <div className="px-4 ">
                                <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                    {user && user.role != "User" ? (
                                        <Link to="/employerApplication/Hourly">
                                            {/* <img src={Logo} alt="logo" style={{ height: '45px', marginTop: "10px", marginLeft: "20%", marginBottom: "20px" }} /> */}
                                            <img
                                                src={Logo}
                                                alt="logo"
                                                style={{
                                                    height: "58px",
                                                    marginTop: "10px",
                                                    marginLeft: "0%",
                                                    marginBottom: "20px",
                                                }}
                                            />
                                        </Link>
                                    ) : (
                                        <a href="/">
                                            {/* <img src={Logo} alt="logo" style={{ height: '45px', marginTop: "10px", marginLeft: "20%", marginBottom: "20px" }} /> */}
                                            <img
                                                src={Logo}
                                                alt="logo"
                                                style={{
                                                    height: "58px",
                                                    marginTop: "10px",
                                                    marginLeft: "0%",
                                                    marginBottom: "20px",
                                                }}
                                            />
                                        </a>
                                    )}
                                </div>
                                {((user && user.role === "Employer") || userType === "Employer") && userLoggedIn ? (
                                    <>
                                        {/*Employer*/}

                                        <p style={{ fontWeight: "500", paddingLeft: "20px" }}>{userType === "Employer" && basicDetails?.name}</p>
                                        <p style={{ fontSize: "13px", marginTop: "-8px", paddingLeft: "20px" }}>{userType === "Employer" && basicDetails?.email}</p>
                                        <p style={{ fontSize: "13px", marginTop: "-8px", paddingLeft: "20px" }}>{userType === "Employer" && "Employer"}</p>
                                        <hr className="my-4" style={{ height: "0.1px" }} />
                                        <div className="mt-3">
                                            <p
                                                onClick={() => {
                                                    navigatePath("/employerApplication/Hourly"), handleAddEvent("Dashboard");
                                                }}
                                                className={location.pathname.startsWith("/employerApplication") ? "SidebarTitleActive" : "SidebarTitle"}
                                            >
                                                Dashboard
                                            </p>
                                            {/* <p onClick={() => navigatePath('/candidate-details')} className={location.pathname === '/candidate-details' ? "SidebarTitleActive" : "SidebarTitle"}>Job Seekers</p> */}
                                            <p
                                                onClick={() => {
                                                    navigatePath("/freelancer-details"), handleAddEvent("Find Talent");
                                                }}
                                                className={location.pathname === "/freelancer-details" ? "SidebarTitleActive" : "SidebarTitle"}
                                            >
                                                Find Talent
                                            </p>
                                            <p
                                                onClick={() => {
                                                    navigatePath("/newpost/0"), handleAddEvent("Post Project");
                                                }}
                                                className={location.pathname === "/newpost/0" ? "SidebarTitleActive" : "SidebarTitle"}
                                                style={{ paddingRight: "30px" }}
                                            >
                                                Post Project
                                            </p>
                                            <p
                                                onClick={() => {
                                                    navigatePath("/support"), handleAddEvent("Help & Support");
                                                }}
                                                className={location.pathname === "/support" ? "SidebarTitleActive" : "SidebarTitle"}
                                            >
                                                Help & Support
                                            </p>
                                        </div>
                                        <hr className="my-4" style={{ height: "0.1px" }} />
                                        <div>
                                            <p className={profile ? "SidebarTitleActive" : "SidebarTitle"} onClick={() => handleProfile()}>
                                                Profile {profile ? <IoIosArrowUp className="ml-3" /> : <IoIosArrowDown className="ml-3" />}
                                            </p>
                                            {profile ? (
                                                <>
                                                    <p
                                                        onClick={() => {
                                                            navigatePath("/myProfile"), handleAddEvent("Edit Profile");
                                                        }}
                                                        className={location.pathname === "/myProfile" ? "SidebarTitleSubActive" : "SidebarTitleSub"}
                                                    >
                                                        Edit Profile
                                                    </p>
                                                    <p
                                                        onClick={() => {
                                                            navigatePath("/change-password"), handleAddEvent("Change Password");
                                                        }}
                                                        className={location.pathname === "/change-password" ? "SidebarTitleSubActive" : "SidebarTitleSub"}
                                                    >
                                                        Change Password
                                                    </p>
                                                    <p
                                                        onClick={() => {
                                                            navigatePath("/myProfile/company"), handleAddEvent("Organization Details");
                                                        }}
                                                        className={location.pathname === "/myProfile/company" ? "SidebarTitleSubActive" : "SidebarTitleSub"}
                                                    >
                                                        Organization Details
                                                    </p>
                                                </>
                                            ) : (
                                                <></>
                                            )}

                                            <p onClick={Logout} className="SidebarTitle">
                                                Logout
                                            </p>
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        {(user && user.role === "User") || userLoggedIn ? (
                                            <>
                                                {/*User*/}
                                                {userProfile && (
                                                    <img
                                                        src={userProfile}
                                                        alt="Profile"
                                                        style={{
                                                            width: "60px",
                                                            height: "60px",
                                                            objectFit: "cover",
                                                            borderRadius: "50%",
                                                            marginBottom: "10px",
                                                            marginLeft: "20px",
                                                        }}
                                                    />
                                                )}
                                                <p style={{ fontWeight: "500", paddingLeft: "20px" }}>{userType === "User" && basicDetails?.name}</p>
                                                <p style={{ fontSize: "13px", marginTop: "-8px", paddingLeft: "20px" }}>{userType === "User" && basicDetails?.email}</p>
                                                <p style={{ fontSize: "13px", marginTop: "-8px", paddingLeft: "20px" }}>{userType === "User" && "Freelancer"}</p>
                                                <hr className="my-4" style={{ height: "0.1px" }} />
                                                <div className="mt-3">
                                                    <p
                                                        onClick={() => {
                                                            navigatePath("/proposal"), handleAddEvent("Applications");
                                                        }}
                                                        className={location.pathname === "/proposal" ? "SidebarTitleActive" : "SidebarTitle"}
                                                    >
                                                        Proposal 
                                                    </p>
                                                    <p
                                                        onClick={() => {
                                                            navigatePath("/matchedHourlyProject/all"), handleAddEvent("hourly projects");
                                                        }}
                                                        className={location.pathname === "/matchedHourlyProject/all" ? "SidebarTitleActive" : "SidebarTitle"}
                                                    >
                                                        Find hourly projects
                                                    </p>
                                                    <p
                                                        onClick={() => {
                                                            navigatePath("/matchedFixedProject/all"), handleAddEvent("fixed cost project");
                                                        }}
                                                        className={location.pathname === "/matchedFixedProject/all" ? "SidebarTitleActive" : "SidebarTitle"}
                                                    >
                                                        Find fixed cost project
                                                    </p>
                                                    {/* <p onClick={() => navigatePath('/contactus')} className={location.pathname === '/contactus' ? "SidebarTitleActive" : "SidebarTitle"}>Contact Us</p> */}
                                                </div>
                                                <hr className="my-4" style={{ height: "0.1px" }} />
                                                <div>
                                                    <p
                                                        onClick={() => {
                                                            navigatePath("/freelancerSavedProject"), handleAddEvent("Saved Projects");
                                                        }}
                                                        className={location.pathname === "/freelancerSavedProject" ? "SidebarTitleActive" : "SidebarTitle"}
                                                    >
                                                        Saved Projects
                                                    </p>

                                                    <p className={profile ? "SidebarTitleActive" : "SidebarTitle"} onClick={() => handleProfile()}>
                                                        Profile {profile ? <IoIosArrowUp className="ml-3" /> : <IoIosArrowDown className="ml-3" />}
                                                    </p>
                                                    {profile ? (
                                                        <>
                                                            {/* <p onClick={() => navigatePath('/editProfileUser')} className={location.pathname === '/editProfileUser' ? "SidebarTitleSubActive" : "SidebarTitleSub"}>Edit Profile</p> */}
                                                            <p
                                                                onClick={() => {
                                                                    navigatePath("/profile"), handleAddEvent("My Profile");
                                                                }}
                                                                className={location.pathname === "/profile" ? "SidebarTitleSubActive" : "SidebarTitleSub"}
                                                            >
                                                                My Profile
                                                            </p>
                                                            <p
                                                                onClick={() => {
                                                                    navigatePath("/change-email"), handleAddEvent("Change Email");
                                                                }}
                                                                className={location.pathname === "/change-email" ? "SidebarTitleSubActive" : "SidebarTitleSub"}
                                                            >
                                                                Change Email
                                                            </p>
                                                            <p
                                                                onClick={() => {
                                                                    navigatePath("/change-password"), handleAddEvent("Change Password");
                                                                }}
                                                                className={location.pathname === "/change-password" ? "SidebarTitleSubActive" : "SidebarTitleSub"}
                                                            >
                                                                Change Password
                                                            </p>
                                                            <p
                                                                onClick={() => {
                                                                    navigatePath("/freelancerbuyconnects"), handleAddEvent("Buy Connects");
                                                                }}
                                                                className={location.pathname === "/freelancerbuyconnects" ? "SidebarTitleSubActive" : "SidebarTitleSub"}
                                                            >
                                                                Buy Connects
                                                            </p>
                                                            <p
                                                                onClick={() => {
                                                                    navigatePath("/freelancerconnectsinformation"), handleAddEvent("Connects Summary");
                                                                }}
                                                                className={location.pathname === "/freelancerconnectsinformation" ? "SidebarTitleSubActive" : "SidebarTitleSub"}
                                                            >
                                                                Connects Summary
                                                            </p>
                                                        </>
                                                    ) : (
                                                        <></>
                                                    )}

                                                    <p onClick={Logout} className="SidebarTitle">
                                                        Logout
                                                    </p>
                                                </div>
                                            </>
                                        ) : (
                                            <>
                                                {/*Not LogedIn*/}
                                                <div className="mt-3">
                                                    <p
                                                        onClick={() => {
                                                            navigatePath("/freelancer-details"), handleAddEvent("Find talent");
                                                        }}
                                                        className={location.pathname === "/freelancer-details" ? "SidebarTitleActive" : "SidebarTitle"}
                                                    >
                                                        Find Talent
                                                    </p>

                                                    <p
                                                        onClick={() => {
                                                            navigatePath("/matchedHourlyProject/all"), handleAddEvent("Hourly project");
                                                        }}
                                                        className={location.pathname === "/matchedHourlyProject/all" ? "SidebarTitleActive" : "SidebarTitle"}
                                                    >
                                                        Find hourly projects
                                                    </p>
                                                    <p
                                                        onClick={() => {
                                                            navigatePath("/matchedFixedProject/all"), handleAddEvent("Fixed cost project");
                                                        }}
                                                        className={location.pathname === "/matchedFixedProject/all" ? "SidebarTitleActive" : "SidebarTitle"}
                                                    >
                                                        Find fixed cost project
                                                    </p>
                                                    {/* <p onClick={() => navigatePath('/login')} className={location.pathname === '/contactus' ? "SidebarTitleActive" : "SidebarTitle"}>Contact Us</p> */}
                                                </div>
                                                <hr className="my-4" style={{ height: "0.1px" }} />
                                                <div>
                                                    <p
                                                        onClick={() => {
                                                            navigatePath("/signup"), handleAddEvent("Sign up");
                                                        }}
                                                        className={location.pathname === "/login" ? "SidebarTitleActive" : "SidebarTitle"}
                                                        style={{ color: "white", backgroundColor: "#1F57C3", borderRadius: "10px" }}
                                                    >
                                                        Sign up
                                                    </p>
                                                    <p
                                                        onClick={() => {
                                                            navigatePath("/login"), handleAddEvent("Login");
                                                        }}
                                                        className={location.pathname === "/login" ? "SidebarTitleActive" : "SidebarTitle"}
                                                    >
                                                        Login
                                                    </p>
                                                </div>
                                            </>
                                        )}
                                    </>
                                )}
                            </div>
                        </Drawer>
                    </div>
                </>
            )}
        </>
    );
}

export default Header2;
