import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { logoutUser } from "../../actions/userActions";
import { useNavigate } from "react-router-dom";
import "./css/Dropdown.scss";
import axios from "axios";
import { logEvent } from "../../utils/Analytics";
const Dropdown = ({ user }) => {
    const [isHovered, setIsHovered] = useState(false);
    const [secondToggle, setSecondToggle] = useState(false);
    const [thirdToggle, setThirdToggle] = useState(false);
    const [fourthToggle, setFourthToggle] = useState(false);
    const displaySecondDropdown = () => {
        setSecondToggle(!secondToggle);
    };
    const [photoUrl, setPhotoUrl] = useState("");
    const userProfile = localStorage.getItem("userProfile");
    const userType = localStorage.getItem("userType");

    const basicDetails = JSON.parse(localStorage.getItem("basicDetails"));

    //console.log(user, "this is user data");

    const displayThirdDropdown = () => {
        setThirdToggle(!thirdToggle);
    };
    const displayFourthDropdown = () => {
        setFourthToggle(!fourthToggle);
    };
    const [resumeData, setResumeData] = useState("");
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const Logout = () => {
        dispatch(logoutUser());
        // window.location.href = "http://localhost:3000/"
        navigate("/");
        window.location.reload();
    };

    const getResumeData = async () => {
        try {
            const { data } = await axios.get("/api/user/getResume", {
                headers: {
                    "Content-Type": "application/json",
                    token: localStorage.getItem("token"),
                },
            });
            setResumeData(data.resume);
            if (user?.role === "User") {
                setPhotoUrl(data?.resume?.PersonalDetails?.photo?.url);
                localStorage.setItem("userProfile", data?.resume?.PersonalDetails?.photo?.url);
            }
        } catch {
            console.log("Something went wrong");
        }
    };
    useEffect(() => {
        if (user && user?.resume) {
            getResumeData();
        }
        if (user?.role === "Employer" && user?.avatar) {
            setPhotoUrl(user?.avatar?.url);
            localStorage.setItem("userProfile", user?.avatar?.url);
        } else {
            setPhotoUrl("");
        }
    }, [user]);

    //console.log(photoUrl, "phtyioy");
    const handleAddEvent = (str) => {
        logEvent("Button", `Clicked ${str} button`, user ? user.role : "Guest");
    };
    return (
        <div
            className="dropdown"
            style={{
                display: "flex",
                alignItems: "center",
                position: "absolute",
                paddingTop: "20px",
                paddingBottom: "20px",
                paddingLeft: "10px",
                paddingRight: "10px",
                top: userProfile ? "-24px" : "-20px",
                right: userProfile ? "-50px" : "-40px",
                background: `${isHovered && user?.role === "User" ? "#c8eeff" : "transparent"}`,
                fontSize: "14px",
            }}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
        >
            <div
                style={{
                    textAlign: "center",
                    color: `${isHovered ? "#008BDC" : "#666"}`,
                    height: userProfile ? "47px" : "35px",
                    width: userProfile ? "44px" : "35px",
                    fontSize: "12px",
                    border: !userProfile && "2px solid gray",
                    borderRadius: "50%",
                    paddingTop: userProfile ? "0px" : "2px",
                    // marginTop: "-0.5px",
                    marginTop: userProfile ? "-2px" : "2px",
                    marginRight: "5px",
                }}
            >
                {/* {user.name.slice(0, 1)} */}
                {userProfile ? (
                    <img
                        src={userProfile}
                        alt="Profile"
                        style={{
                            width: "100%",
                            height: "100%",
                            objectFit: "cover", // Ensures the image covers the div
                            borderRadius: "50%",
                            boxShadow: "0 0 5px rgba(0,0,0,0.2)",
                            border: "2px solid white",
                        }}
                    />
                ) : (
                    <p style={{ fontSize: "18px" }}>{basicDetails?.name.slice(0, 1)}</p>
                )}
            </div>

            {isHovered ? (
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#008BDC" className="bi bi-caret-up-fill" viewBox="0 0 16 16">
                    <path d="m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z" />
                </svg>
            ) : (
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#666" className="bi bi-caret-down-fill" viewBox="0 0 16 16">
                    <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                </svg>
            )}

            {isHovered && (
                <div
                    style={{
                        position: "absolute",
                        top: "100%",
                        right: "0",
                        zIndex: 1,
                        backgroundColor: "#fff",
                        // paddingRight: '10px',
                        paddingTop: "10px",
                        paddingBottom: "20px",
                        boxShadow: "0 2px 4px rgba(0,0,0,0.2)",
                        borderRadius: "1px",
                        minWidth: "210px",
                    }}
                >
                    {/* Dropdown content goes here */}
                    <div
                        onClick={() => {
                            user?.role === "User" ? navigate("/profile") : navigate("/myProfile");
                            setIsHovered(false);
                        }}
                        className=" profile-container"
                    >
                        {photoUrl && <img src={photoUrl} alt="Profile" className=" user-profile-pic" />}
                        <div className="hover-item" style={{ marginTop: "5px", paddingLeft: "10px" }}>
                            <a className="user-name">{userType && basicDetails?.name}</a>
                            <p className="user-email" style={{ fontSize: "13px", fontWeight: "400", marginBottom: "0px" }}>
                                {userType && basicDetails?.email}
                            </p>
                            <p className="" style={{ fontSize: "13px", fontWeight: "400" }}>
                                {userType === "User" ? "Freelancer" : "Employer"}
                            </p>
                        </div>
                    </div>

                    <div
                        style={{
                            height: "1px",
                            width: "100%",
                            backgroundColor: "#f2f2f2",
                        }}
                    />

                    {user?.role === "User" || userType === "User" ? (
                        <ul>
                            <li>
                                <a style={{ marginTop: "10px", marginBottom: "10px" }} href="/proposal" onClick={handleAddEvent("My Proposal")}>
                                    My Proposal 
                                </a>
                            </li>

                            <li>
                                <a style={{ marginTop: "10px", marginBottom: "10px" }} href="/profile" onClick={handleAddEvent("My Profile")}>
                                    My Profile
                                </a>
                            </li>

                            <li>
                                <a style={{ marginTop: "10px", marginBottom: "10px" }} href="/freelancerSavedProject" onClick={handleAddEvent("Saved Projects")}>
                                    Saved Projects
                                </a>
                            </li>
                        </ul>
                    ) : (
                        <ul>
                            <li>
                                <a style={{ marginTop: "10px", marginBottom: "10px" }} href="/support" onClick={handleAddEvent("Help & Support")}>
                                    Help & Support
                                </a>
                            </li>
                            <li>
                                <a style={{ marginTop: "10px", marginBottom: "10px" }} href="/myProfile" onClick={handleAddEvent("Personal Details")}>
                                    Personal Details
                                </a>
                            </li>
                            <li>
                                <a style={{ marginTop: "10px", marginBottom: "10px" }} href="/myProfile/company" onClick={handleAddEvent("Organization Details")}>
                                    Organization Details
                                </a>
                            </li>
                            
                        </ul>
                    )}
                    {(user?.role === "User" || userType === "User") && (
                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                                marginTop: "10px",
                                cursor: "pointer",
                                fontSize: "14px",
                                paddingLeft: "20px",
                                paddingRight: "10px",
                            }}
                            className="hover-item"
                            onClick={displayFourthDropdown}
                        >
                            {(user?.role === "User" || userType === "User") && "Connects"}
                            {(user?.role === "User" || userType === "User") && (
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-down" viewBox="0 0 16 16">
                                    <path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708" />
                                </svg>
                            )}
                        </div>
                    )}

                    {(user?.role === "User" || userType === "User") && fourthToggle ? (
                        <div
                            style={{
                                marginTop: "10px",
                                cursor: "pointer",
                                fontSize: "14px",
                                paddingLeft: "20px",
                            }}
                            onClick={displayFourthDropdown}
                        >
                            {fourthToggle && (
                                <ul style={{marginTop: "10px",cursor: "pointer",}}>
                                    <li>
                                        <a style={{ marginTop: "10px", marginBottom: "10px" }} href="/freelancerbuyconnects" onClick={handleAddEvent("Buy Connects")}>
                                            Buy Connects
                                        </a>
                                    </li>

                                    <li>
                                        <a style={{ marginTop: "10px", marginBottom: "10px" }} href="/freelancerconnectsinformation" onClick={handleAddEvent("Connects Summary")}>
                                            Connects Summary
                                        </a>
                                    </li>
                                </ul>
                            )}
                        </div>
                    ) : null}
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            marginTop: "10px",
                            cursor: "pointer",
                            fontSize: "14px",
                            paddingLeft: "20px",
                            paddingRight: "10px",
                        }}
                        className="hover-item"
                        onClick={displaySecondDropdown}
                    >
                        {/* {user.role === "User" ? "More" : "Manage Account"} */}
                        {user?.role === "User" ? "Manage Account" : "Manage Account"}

                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-down" viewBox="0 0 16 16">
                            <path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708" />
                        </svg>
                    </div>

                    {/* Second dropdown content goes here */}

                    {/* {secondToggle && (
            <div
              style={{
                marginTop: '10px',
                cursor: 'pointer',
                fontSize: '14px',
                paddingLeft: '20px',
              }}
              onClick={displayThirdDropdown}
            >
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <div
                  className="hover-item"
                  style={{
                    fontSize: '14px',
                    marginLeft: '10px',
                  }}
                >
                  Manage Account
                </div>

                {thirdToggle ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-chevron-up"
                    viewBox="0 0 16 16"
                    style={{ marginRight: '10px' }}
                  >
                    <path
                      fill-rule="evenodd"
                      d="M7.646 4.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1-.708.708L8 5.707l-5.646 5.647a.5.5 0 0 1-.708-.708z"
                    />
                  </svg>
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-chevron-down"
                    viewBox="0 0 16 16"
                    style={{ marginRight: '10px' }}
                  >
                    <path
                      fill-rule="evenodd"
                      d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708"
                    />
                  </svg>
                )}
              </div>

              {thirdToggle && (
                <ul
                  style={{
                    marginTop: '10px',
                    cursor: 'pointer',
                  }}
                >
                  <li>
                    <a
                      style={{ marginTop: '10px', marginBottom: '10px' }}
                      href="/addresume"
                    >
                      Your Resume
                    </a>
                  </li>

                  <li>
                    <a
                      style={{ marginTop: '10px', marginBottom: '10px' }}
                      href="/myProfile"
                    >
                      Edit Profile
                    </a>
                  </li>

                  <li>
                    <a
                      style={{ marginTop: '10px', marginBottom: '10px' }}
                      href="/change-email"
                    >
                      Change Email
                    </a>
                  </li>

                  <li>
                    <a
                      style={{ marginTop: '10px', marginBottom: '10px' }}
                      href="/change-password"
                    >
                      Change Password
                    </a>
                  </li>
                </ul>
              )}
            </div>
          )} */}

                    {user?.role === "User" || userType === "User"
                        ? secondToggle && (
                              <ul
                                  style={{
                                      marginTop: "10px",
                                      cursor: "pointer",
                                      marginLeft: "15px",
                                  }}
                              >
                                  <li>
                                      <a style={{ marginTop: "10px", marginBottom: "10px" }} href="/change-email" onClick={handleAddEvent("Change Email")}>
                                          Change Email
                                      </a>
                                  </li>

                                  <li>
                                      <a style={{ marginTop: "10px", marginBottom: "10px" }} href="/change-password" onClick={handleAddEvent("Change Password")}>
                                          Change Password
                                      </a>
                                  </li>
                              </ul>
                          )
                        : secondToggle && (
                              <ul
                                  style={{
                                      marginTop: "10px",
                                      cursor: "pointer",
                                      marginLeft: "15px",
                                  }}
                              >
                                  <li>
                                      <a style={{ marginTop: "10px", marginBottom: "10px" }} href="/myProfile" onClick={handleAddEvent("Edit Profile")}>
                                          Edit Profile
                                      </a>
                                  </li>

                                  <li>
                                      <a style={{ marginTop: "10px", marginBottom: "10px" }} href="/change-password" onClick={handleAddEvent("Change Password")}>
                                          Change Password
                                      </a>
                                  </li>
                              </ul>
                          )}

                    {/* {user.role === "User"
            ? secondToggle && (
                <div
                  style={{
                    marginTop: "10px",
                    cursor: "pointer",
                    fontSize: "14px",
                    paddingLeft: "20px",
                  }}
                  onClick={displayThirdDropdown}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <div
                      className="hover-item"
                      style={{
                        fontSize: "14px",
                        marginLeft: "10px",
                      }}
                    >
                      Manage Account
                    </div>

                    {thirdToggle ? (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        className="bi bi-chevron-up"
                        viewBox="0 0 16 16"
                        style={{ marginRight: "10px" }}
                      >
                        <path
                          fill-rule="evenodd"
                          d="M7.646 4.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1-.708.708L8 5.707l-5.646 5.647a.5.5 0 0 1-.708-.708z"
                        />
                      </svg>
                    ) : (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        className="bi bi-chevron-down"
                        viewBox="0 0 16 16"
                        style={{ marginRight: "10px" }}
                      >
                        <path
                          fill-rule="evenodd"
                          d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708"
                        />
                      </svg>
                    )}
                  </div>

                  {thirdToggle && (
                    <ul
                      style={{
                        marginTop: "10px",
                        cursor: "pointer",
                      }}
                    >
                      <li>
                        <a style={{ marginTop: "10px", marginBottom: "10px" }} href="/change-email">
                          Change Email
                        </a>
                      </li>

                      <li>
                        <a style={{ marginTop: "10px", marginBottom: "10px" }} href="/change-password">
                          Change Password
                        </a>
                      </li>
                    </ul>
                  )}
                </div>
              )
            : secondToggle && (
                <ul
                  style={{
                    marginTop: "10px",
                    cursor: "pointer",
                    marginLeft: "15px",
                  }}
                >
                  <li>
                    <a style={{ marginTop: "10px", marginBottom: "10px" }} href="/myProfile">
                      Edit Profile
                    </a>
                  </li>

                  <li>
                    <a style={{ marginTop: "10px", marginBottom: "10px" }} href="/change-password">
                      Change Password
                    </a>
                  </li>
                </ul>
              )} */}

                    <div
                        style={{
                            fontSize: "14px",
                            marginTop: "10px",
                            marginLeft: "20px",
                            cursor: "pointer",
                        }}
                        onClick={Logout}
                        className="hover-item"
                    >
                        Logout
                    </div>
                </div>
            )}
        </div>
    );
};

export default Dropdown;
